import { request } from './common'
import { OPERATION_LOG_RESERVATION, OPERATION_UNLOG_RESERVATION } from '../constants/order-constants'
import QueryString from 'qs'

const getDepartureDates = (tour) => {
  const departureDates = tour?.tourOptions?.flatMap(tourOption => (
    tourOption?.seasons?.flatMap(season => (
      season?.departures?.flatMap(departure => departure.startDate)
    ))
  ))

  return Array.from(new Set(departureDates))
}

const extractSeasonFields = (tourOption) => tourOption?.seasons?.map(season => ({
  seasonId: season.id,
  startDate: season.fromDate,
  endDate: season.toDate,
}))

export default class TourService {
  static async getToursByVendorId(vendorId) {
    const url = `${window.configs.COMMERCE_HOST}/api/v2/tours?vendorId=${vendorId}&departureAvailability=all`

    const response = await request(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    const tours = response?.result?.tours ?? []

    const thinnedDownTours = tours.map(tour => ({
      id: tour.id,
      tourName: tour.name,
      dealId: tour.sourceTourId,
      type: 'tour_v2',
      tourOptions: (tour?.tourOptions ?? []).map(tourOption => ({
        tourOptionId: tourOption.id,
        dealOptionName: tourOption.sourceTourOptionName ?? '',
        tourOptionName: tourOption?.seasons?.[0]?.name ?? '',
        seasons: extractSeasonFields(tourOption) ?? [],
      })),
      image: tour?.tourOptions?.[0]?.seasons?.[0]?.images?.[0]?.id ?? '',
      departureDates: getDepartureDates(tour),
      schedules: tour?.schedules ?? [],
    }))

    return thinnedDownTours
  }

  static async logOrUnlogReservation(reservationId, logged) {
    const url = `${window.configs.COMMERCE_HOST}/api/v2/tours/reservations/${reservationId}`
    const operation = logged ? OPERATION_LOG_RESERVATION : OPERATION_UNLOG_RESERVATION
    const body = JSON.stringify({ op: operation })

    const response = await request(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body,
    })

    return response.result
  }

  static async getReservation(reservationId) {
    const url = `${window.configs.COMMERCE_HOST}/api/v2/tours/reservations/${reservationId}/snapshot`

    const response = await request(url, {
      method: 'GET',
    })

    return response.result
  }

  static listVendorReservations(vendorId, params) {
    const query = QueryString.stringify(params)
    const url = `${window.configs.COMMERCE_HOST}/api/v2/tours/vendors/${vendorId}/reservations?${query}`

    return request(url, {
      method: 'GET',
      params,
    })
  }

  static listSubVendorReservations(vendorId, params) {
    const query = QueryString.stringify(params)
    const url = `${window.configs.COMMERCE_HOST}/api/v2/tours/sub-vendors/${vendorId}/reservations?${query}`

    return request(url, {
      method: 'GET',
      params,
    })
  }
}
